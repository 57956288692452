import React from 'react';
import styled from 'styled-components';

const StoryText = styled.p`
  color: #333;
  font-size: 16px;
  margin: 10px 15%;
  text-align: left;
`;

const Story = () => (
  <>
    <StoryText>很久以前，在中国的古老土地上，坐落在碧绿的山脉和宁静的东海之间，存在着一个神秘的王国。这个王国由一位博学多才、仁慈的皇帝统治，他深谙风水和易经之道。他相信，了解数字与宇宙力量之间的神秘关系是实现和谐与繁荣的关键。
	  据说在紫禁城的心脏地带有一个神圣的密室，里面存放着‘命运之轮’。这个宏伟的轮盘，饰有复杂的符号和从0到99的数字，是宇宙本身的体现。每年冬至之夜，在满月的银色光芒下，皇帝会举行一个受人尊敬的仪式。他会旋转这个轮盘，让它在光影中旋转。
	  转动轮盘被认为是协调天地之气，揭示了一个对来年具有巨大影响力的数字。这个数字将指导王国从丰收到皇家法令的各种决策。王国的人民屏息以待，因为他们知道他们的命运与轮盘揭示的神秘数字息息相关。
	  如今，几个世纪后，这个古老的传统仍在继续。当您站在虚拟轮盘前时，想象古代鼓声的回响和圣贤们的低语。转动轮盘，拥抱古人的智慧，找到与您的命运共鸣的数字。</StoryText>
    {/* Add more paragraphs as needed */}
  </>
);

export default Story;
