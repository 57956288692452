import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const ResultText = styled.p`
  font-size: 24px; // Larger font size for the text
  color: #cc0000; // Optional: Color adjustment for better visibility
  margin: 0 0 10px 0; // Adjust spacing as needed
`;

const ResultNumber = styled.div`
  font-size: 36px; // Even larger font size for the number
  font-weight: bold; // Optional: Making the number bold
  color: #cc0000;
  margin-bottom: 20px; // Spacing below the number
`;

const WheelContainer = styled.div`
  position: relative;
  margin: 20px auto;
  width: 200px;
  height: 200px;
`;

const SpinWheel = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: conic-gradient(
    red, orange, yellow, green, blue, indigo, violet
  );
  line-height: 200px;
  text-align: center;
`;

const SpinButton = styled.button`
  background-color: #cc0000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
`;

const ResultPopup = styled(motion.div)`
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  padding: 20px;
  background-color: white;
  border: 2px solid #cc0000;
  border-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  background-color: #cc0000;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
`;

const Wheel = () => {
  const [rotationDegrees, setRotationDegrees] = useState(0);
  const [luckyNumber, setLuckyNumber] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const spinWheel = () => {
    const spinCount = 5;
    const randomSpin = Math.floor(Math.random() * 360);
    const newDegrees = rotationDegrees + spinCount * 360 + randomSpin;
    setRotationDegrees(newDegrees);

    setTimeout(() => {
      const number = Math.floor(randomSpin / 3.6);
      // Format number to be two digits if less than 10
      const formattedNumber = number < 10 ? `0${number}` : number.toString();
      setLuckyNumber(formattedNumber);
      setShowPopup(true);
    }, 4000);
  };

  const popupVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 }
  };

  return (
    <WheelContainer>
      <SpinWheel
        animate={{ rotate: rotationDegrees }}
        transition={{ duration: 4, ease: "easeOut" }}
      />
      <SpinButton onClick={spinWheel}>转动轮盘</SpinButton>
      <AnimatePresence>
        {showPopup && (
          <ResultPopup
            variants={popupVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ResultText>幸运数字:</ResultText>
            <ResultNumber>{luckyNumber}</ResultNumber>
            <CloseButton onClick={() => setShowPopup(false)}>关闭</CloseButton>
          </ResultPopup>
        )}
      </AnimatePresence>
    </WheelContainer>
);
};

export default Wheel;
