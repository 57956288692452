import React from "react";
import Wheel from "./Wheel";
import Story from "./Story";
import styled from "styled-components";

const Container = styled.div`
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  background-image: url('https://pub-6e59080839c44d3ea91825f4e41120c4.r2.dev/fengsui.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px; // Padding on all sides
  padding-bottom: 60px; // Additional padding at the bottom
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8); // Slightly more opaque for better readability
  border-radius: 15px; // Softens the edges of the overlay
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); // Adds a subtle shadow for depth
  padding: 20px;
  width: 80%; // Adjust width as needed
  max-width: 800px; // Max width to maintain proportions
`;

const Title = styled.h1`
  color: #cc0000;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px; // Adds space below the title
`;

function App() {
  return (
    <Container>
      <Overlay>
        <Title>发现您的风水幸运数字 Your Lucky Number</Title>
        <Story />
        <Wheel />
      </Overlay>
    </Container>
  );
}

export default App;
